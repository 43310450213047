<template>
  <div class="statistics">
    <!--  <StuStatistics></StuStatistics>-->
    <div class="top">
      <div class="tab-list">
        <div v-for="item in tabList" :key="item.id" :class="curTab.id == item.id ? 'active' : ''" @click="changeTab(item)">{{item.name}}</div>
      </div>
    </div>
    <component :is="curTab.componentName" :siteId="siteId" :isSuper="isSuper" :isManager="isManager"></component>
  </div>
</template>

<script>
// import StuStatistics from '@/components/stuStatistics'
import TeacherMaterialAnalysis from '../../../trainingcamp/analysis/teacherMaterialAnalysis';
import TeacherAttestationAnalysis from '../../../trainingcamp/analysis/teacherAttestationAnalysis';
export default {
  name: "trainstat",
  data(){
    return {
      tabList:[
        // {
        //   name:'教材练习',
        //   id:1,
        //   componentName:'TeacherMaterialAnalysis'
        // },
        {
          name:'认证练习',
          id:2,
          componentName:'TeacherAttestationAnalysis'
        },
      ],
      curTab:{
        name:'认证练习',
        id:2,
        componentName:'TeacherAttestationAnalysis'
      },
      siteId:'',
    }
  },
  props: {
    isSuper: {
      type: Boolean,
      default: false,
    },
    isManager:{
      type: Boolean,
      default: false,
    }
  },
  components:{
    // StuStatistics
    TeacherMaterialAnalysis,
    TeacherAttestationAnalysis
  },
  watch:{
    // '$store.state.user.userInfo':{
    //   handler(newV){
    //     console.log(newV,'dddd')
    //     if(newV.id){
    //       //0 高校 1 分销商  0 显示教材  其他为认证
    //       if(this.$store.state.user.userInfo.id && this.$store.state.user.userInfo.site.type == 0){
    //         this.curTab = {
    //           name:'教材练习',
    //           id:1,
    //           componentName:'TeacherMaterialAnalysis'
    //         };
    //       }else{
    //         this.curTab = {
    //           name:'认证练习',
    //           id:2,
    //           componentName:'TeacherAttestationAnalysis'
    //         };
    //       }
    //
    //     }
    //   },
    //   immediate:true,
    // }
  },
  created(){
    this.siteId = this.$route.params.id;
  },
  methods:{
    changeTab(data){
      this.curTab = data;
    }
  }

}
</script>

<style scoped lang="scss">
.statistics{
   margin: -20px;
  .top{
    margin-top: 20px;
    height: 64px;
    background-color: #FFFFFF;
    .tab-list{
      margin:0 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      height: 100%;
      border-bottom: 2px solid #EEEEEE;
      font-size: 16px;
      color: #666666;
      >div{
        padding: 0 5px;
        margin-right: 30px;
        height: 54px;
        display: flex;
        align-items: center;
        border-bottom: 4px solid transparent;
        cursor: pointer;
      }
      .active{
        font-weight: bold;
        color: #4A79FF;
        border-bottom: 4px solid #5782FF;
      }
    }
  }
}
</style>
