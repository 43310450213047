<template>
  <div class="my-site">
    <div class="my-site-cont">
      <MyBreadcrumb></MyBreadcrumb>
    </div>

    <Detail :isManager="true"></Detail>
  </div>
</template>
<script>
import Detail from './components/detail/detail'
export default {
  name:'my-site',
  data(){
    return {
    }
  },
  components:{
    Detail
  },
}
</script>
<style scoped lang="scss">
.my-site-cont{
  margin: 20px 0 0 20px;
}
</style>
