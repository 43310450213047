<template>
  <div class="tab">
    <div class="tab-item" :class="curTabId == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
  </div>
</template>

<script>
export default {
  name: "tabCategoty",
  data(){
    return {
      tabList:[
        {
          name:'教材题库',
          id:'0',
          tab:'material'
        },
        {
          name:'认证题库',
          id:'1',
          tab:'attestation'
        },
      ],
      curTabId:'0'
    }
  },
  props:{
    dataList:{
      type:Array,
      default:()=>{
        return [];
      },
    },
    curTabIdP:{
      type:String,
      default:'0'
    }
  },
  watch:{
    dataList:{
      handler(newV){
        console.log(newV,'newVnewV============')
        if(newV.length){
          this.tabList = newV;
          this.init();
        }
      },
      immediate:true
    },
    curTabIdP:{
      handler(newV){
        console.log(newV,'newVnewVnewV')
        this.curTabId = newV;

      },
      immediate:true
    }
  },
  created(){
    console.log('111111')


  },
  methods:{
    init(){
      if(this.$route.query.tab){
        let curTab = this.tabList.filter((item)=>{
          return item.tab == this.$route.query.tab;
        })[0];
        this.curTabId = curTab.id;
        this.$emit('changeTab',curTab);

      }else{
        // this.$emit('changeTab',{
        //   name:'教材题库',
        //   id:'0',
        //   tab:'material'
        // });
      }
    },
    changeTab(data){
      this.curTabId = data.id;
      this.$emit('changeTab',data);
    }
  }
}
</script>

<style scoped lang="scss">
.tab{
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: #666666;
  .tab-item{
    margin-right: 32px;
    padding-bottom: 12px;
    border-bottom: 4px solid transparent;
  }
  .active{
    font-weight: bold;
    color: #4A79FF;
    border-bottom: 4px solid #5782FF;
  }
}
</style>
