<template>
  <div>
    <div class="stu-attestation-analysis" v-if="info && info.categoryList.length">
      <div class="cont train">
        <div class="train-r">
          <div class="cont-top">
            <div class="tit">各分类练习情况</div>
            <div class="cont-top-r">
              <span class="legend"></span>
              <p>练习数量</p>
            </div>
          </div>
          <div id="categoryBar" style="width: 100%;height: 400px"></div>
        </div>
      </div>
      <div class="cont mt10 category">
        <div class="tit">查看各分类练习分析</div>
        <div class="category-list mt20">
          <div :class="curCategoryId == item.category_id ? 'active' : ''" v-for="item in info.categoryList" :key="item.id" @click="changeCategory(item)">{{ item.fullName }}</div>
        </div>
        <div class="category-stat">
          <div class="category-stat-info flex2" style="margin-right: 20px">
            <div>
              <div class="num">{{ categoryInfo.trainAverageDataCount }}</div>
              <div>平均练习题数</div>
            </div>
            <div>
              <div class="num">{{ categoryInfo.trainAverageUsetime }}</div>
              <div>平均练习时长</div>
            </div>
          </div>
          <div class="category-stat-info flex2">
            <div>
              <div class="num">{{ categoryInfo.trainUserCount }}</div>
              <div>参与人数</div>
            </div>
            <div>
              <div class="num">{{ categoryInfo.trainNotJoinUserCount }}</div>
              <div>未练习人数</div>
            </div>
          </div>
        </div>
      </div>

      <div class="cont mt10">
        <div class="cont-top">
          <div class="tit">每日刷题人数:</div>
          <div class="cont-top-r">
            <div>
              <span class="legend"></span>
              <p>刷题人数</p>
            </div>
          </div>
        </div>
        <div id="category-day-user-chart" style="width: 100%;height: 400px;"></div>
      </div>
      <div class="cont mt10">
        <div class="cont-top">
          <div class="tit">每日平均练习题数:</div>
          <div class="cont-top-r">
            <div>
              <span class="legend"></span>
              <p>练习题数</p>
            </div>
          </div>
        </div>
        <div id="category-day-data-chart" style="width: 100%;height: 400px;"></div>
      </div>
      <div class="cont mt10">
        <div style="display: flex; justify-content: space-between;align-items: center">
          <div class="tit">学生刷题排行:</div>
          <Button type="primary" @click="exportRank" :loading="downLoading">导出</Button>
        </div>

        <div class="table">
          <Table :columns="columns" :data="dataList">
            <template slot-scope="{ row, index }" slot="op">
              <Button type="primary"  style="margin-right: 5px" @click="goStuDetail(row)">学生详情</Button>
            </template>
          </Table>
        </div>
        <div class="page">
          <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
        </div>
      </div>
      <div class="cont-s mt10">
        <div class="cont" v-if="categoryInfo.category && categoryInfo.category.map_id != '0'">
          <div class="tit">涵盖教材章节正确率:</div>
          <div class="absorb-l-cont" v-show="!moreLoading && categoryInfoMore.mapTree && categoryInfoMore.mapTree.length">
            <div class="absorb-l-cont-item" v-for="item in categoryInfoMore.mapTree" :key="item.id">
              <div class="absorb-l-cont-item-tit">{{ item.node.name }}</div>
              <div class="absorb-l-cont-item-list">
                <div class="absorb-l-cont-item-list-item" v-for="sItem in item.children" :key="sItem.id">
                  <div class="absorb-l-cont-item-list-item-name">{{ sItem.node.name }}</div>
                  <div class="absorb-l-cont-item-list-item-progress">
                    <div class="progress-bj"><div class="progress-val" :style="{width:(sItem.train_count !=0 ? (sItem.right_count/sItem.train_count*100) : 0) + '%'}"></div></div>
                    <div class="absorb-l-cont-item-list-item-progress-nr">共{{sItem.data_count}}题，正确率：{{ sItem.train_count !=0 ? (sItem.right_count/sItem.train_count*100).toFixed(2) : 0 }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="no-data" style="padding: 42px 0;" v-show="!moreLoading && categoryInfoMore.mapTree && !categoryInfoMore.mapTree.length">
            <img src="../../../assets/images/new_icon/kong.png" width="355" height="190">
            <p>空空如也...</p>
          </div>
          <div v-show="moreLoading" style="position: relative;width: 100%;height: 300px">
            <Spin fix></Spin>
          </div>
        </div>
        <div class="cont">
          <div class="tit">整体知识点掌握分析:</div>
          <div class="absorb-r-cont" v-show="!moreLoading && categoryInfoMore.keypointList && categoryInfoMore.keypointList.length">
            <div class="absorb-r-cont-item" v-for="item in categoryInfoMore.keypointList" :key="item.label_id">
              <div class="absorb-r-cont-item-name">{{ item.label_name }}</div>
              <div class="absorb-r-cont-item-progress">
                <div class="progress-bj"><div class="progress-val" :style="{width:(item.total != '0' ? (item.right_count / item.total * 100) : 0) + '%'}"></div></div>
                <div class="absorb-r-cont-item-progress-nr">掌握程度：{{ item.total != '0' ? (item.right_count / item.total * 100).toFixed(2) : 0 }}%</div>
              </div>
            </div>
          </div>
          <div class="no-data" style="padding: 42px 0;" v-show="!moreLoading && categoryInfoMore.keypointList && !categoryInfoMore.keypointList.length">
            <img src="../../../assets/images/new_icon/kong.png" width="355" height="190">
            <p>空空如也...</p>
          </div>
          <div v-show="moreLoading" style="position: relative;width: 100%;height: 300px">
            <Spin fix></Spin>
          </div>
        </div>

      </div>
      <div class="cont mt10">
        <div class="cont-top">
          <div class="tit">整体题型正确率:</div>
          <div class="cont-top-r">
            <div>
              <span class="legend"></span>
              <p>正确率</p>
            </div>
          </div>
        </div>
        <div v-show="!moreLoading" id="type-rate" style="width: 100%;height: 300px"></div>
        <div v-show="moreLoading" style="position: relative;width: 100%;height: 300px">
          <Spin fix></Spin>
        </div>
      </div>

    </div>
    <div class="no-data" v-if="info && !info.categoryList.length">
      <img src="../../../assets/images/new_icon/kong.png" width="355" height="190">
      <p>您还没有练习过认证题...</p>
    </div>
  </div>

</template>

<script>
import util from "@/utils/tools.js";
export default {
  name: "stuAttestationAnalysis",
  data(){
    return {
      columns:[
        {
          title: 'ID',
          key: 'user_id'
        },
        {
          title: '学生名称',
          key: 'name',
          render:(h,params)=>{
            return h('span',(params.row.user && params.row.user.nickname))
          }
        },
        {
          title: '总刷题量',
          key: 'train_count'
        },
        {
          title:"浏览量",
          key:"view_count"
        },
        {
          title: '正确率',
          key: 'right_rate'
        },
        {
          title: '今日刷题量',
          key: 'userToday',
          render:(h,params)=>{
            return h('span',(params.row.userToday  ? params.row.userToday.train_count : '-'))
          }
        },
        {
          title: '操作',
          key: 'address',
          slot:'op'

        }
      ],
      dataList:[
      ],
      curCategoryId:'',
      info:'',
      categoryInfo:{},
      pageStatus:true,
      total:0,
      page:1,
      pageSize:10,
      categoryInfoMore:{},
      moreLoading:false,
      downLoading:false,
    }
  },
  props:{
    siteId:{
      type:String,
      default:''
    },
    isSuper: {
      type: Boolean,
      default: false,
    },
    isManager:{
      type: Boolean,
      default: false,
    }
  },
  mounted(){
    this.getData();
  },
  beforeDestroy(){
    this.pageStatus = false;
  },
  methods:{
    getData(){
      let params = {
        category_type:'0',
      }
      if(this.siteId){
        params.site_id = this.siteId
      }
      this.api.home.statBySite(params).then((res)=>{
        this.info = res;
        if(this.info.categoryList.length){
          this.info.categoryList.forEach((item)=>{
            let name = item.categoryList.map((sItme)=>{
              return sItme.name;
            }).join('-');
            item.fullName = name;
          })

          this.curCategoryId = this.info.categoryList[0].category_id;

          this.$nextTick(()=>{
            if(this.pageStatus){
              this.setCatergoryBarOption();
            }
          })

          this.getCategoryData();
          this.getCategoryDataMore();
        }

      })
    },
    setCatergoryBarOption(){
      let chartDom = document.getElementById('categoryBar');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      this.info.categoryList.forEach((item)=>{
        names.push(item.fullName);
        counts.push(item.train_count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          show:false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '0',
          top:'20px',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '练习数量',
            type: 'bar',
            stack: 'Total',
            data: counts,
            barMaxWidth:'60'
          },
        ]
      };
      option && myChart.setOption(option);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },
    getCategoryData(){
      let params = {
        category_id:this.curCategoryId
      }
      if(this.siteId){
        params.site_id = this.siteId
      }
      this.api.analysis.statBySiteAndCategory(params).then((res)=>{
        this.categoryInfo = res;

        this.getList();
        if(this.pageStatus){
          this.setCatergoryBarOption();
          this.setCategoryDayUserChart();
          this.setCategoryDayDataChart();
        }
      })
    },
    getCategoryDataMore(){
      let params = {
        category_id:this.curCategoryId
      }
      if(this.siteId){
        params.site_id = this.siteId
      }
      this.moreLoading = true;
      this.api.analysis.statBySiteAndCategoryMore(params).then((res)=>{
        this.moreLoading = false;
        this.categoryInfoMore = res;
        this.$nextTick(()=>{
          if(this.pageStatus){
            this.setTypeRateChart();
          }
        })
      }).catch((err)=>{
        this.moreLoading = false;
      })
    },
    setCategoryDayUserChart(){
      let chartDom = document.getElementById('category-day-user-chart');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      this.categoryInfo.statUserDayStat.forEach((res)=>{
        names.push(res.date);
        counts.push(res.user_count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          show:false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '刷题人数',
            type: 'line',
            stack: 'Total',
            data: counts
          },
        ]
      };

      option && myChart.setOption(option);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })

    },
    setCategoryDayDataChart(){
      let chartDom = document.getElementById('category-day-data-chart');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      this.categoryInfo.statUserDayStat.forEach((res)=>{
        names.push(res.date);
        counts.push(res.train_count);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          show:false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '练习题数',
            type: 'line',
            stack: 'Total',
            data: counts
          },
        ]
      };

      option && myChart.setOption(option);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })

    },
    setTypeRateChart(){
      let chartDom = document.getElementById('type-rate');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      this.categoryInfoMore.dataTypeList.forEach((res)=>{
        names.push(res.name);
        counts.push((res.right_count/res.data_count * 100).toFixed(2));
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          show:false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '0',
          top:'40px',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '正确率',
            type: 'bar',
            stack: 'Total',
            data: counts,
            barMaxWidth:'60'
          },
        ]
      };

      option && myChart.setOption(option);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })

    },
    changeCategory(data){
      this.curCategoryId = data.category_id;
      this.getCategoryData();
      this.getCategoryDataMore();
    },
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        category_id:this.curCategoryId,
      }
      if(this.siteId){
        params.site_id = this.siteId
      }

      this.loading = true;
      this.api.dataset.datasetTrainStatUserList(params).then((res)=>{
        this.loading = false;
        this.dataList = res.list;
        this.total = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    goStuDetail(data){
      console.log(this.isManager,'++++++++++++++')
      if(this.isSuper){
        this.$router.push({
          // path:'/user/detail',
          // query:{
          //   id:data.user_id,
          //   tab: 'index'
          // },
          name:'user/detail',
          params: {
            id: data.user_id,
            tab: 'index'
          }
        })
      }else if(this.isManager){
        this.$router.push({
          // path:'/user/detail',
          // query:{
          //   id:data.user_id,
          //   tab: 'index'
          // },
          name:'manager/user/detail',
          params: {
            id: data.user_id,
            tab: 'index'
          }
        })
      }else{
        this.$router.push({
          // path:'/user/detail',
          // query:{
          //   id:data.user_id,
          //   tab: 'index'
          // },
          name:'course/user/detail',
          params: {
            id: data.user_id,
            tab: 'index'
          }
        })
      }

    },
    exportRank(){
      let params = {
        category_id:this.curCategoryId,
      }
      if(this.siteId){
        params.site_id = this.siteId
      }

      this.downLoading= true;
      this.api.dataset.datasetTrainStatUserListExport(params).then((res)=>{
        let uploadParams = {
          file:res.filepath
        };
        this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
          this.downLoading= false;
          util.downLoad(res)
        })
      }).catch((err)=>{
        this.downLoading= false;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.stu-attestation-analysis{
  padding-bottom: 20px;
  font-size: 14px;
  .cont{
    padding: 20px;
    background-color: #FFFFFF;
  }
  .train{
    display: flex;
    justify-content: flex-start;
    .train-r{
      flex: 5;
    }
  }
  .category{
    .category-list{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      color: #333333;
      >div{
        //margin-right: 28px;
        //margin-bottom: 10px;
        //cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 0 10px;
        height: 42px;
        background: #F6F7FA;
        border-radius: 6px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        line-height: 42px;
      }
      .active{
        //font-weight: bold;
        //color: #4A79FF;
        background: #4A79FF;
        color: #FFFFFF;
      }
    }
    .category-stat{
      display: flex;
      justify-content: flex-start;
      .category-stat-info{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 98px;
        background: #F6F7FA;
        border-radius: 6px;
        >div{
          flex: 1;
          text-align: center;
          color: #666666;
          .num{
            margin-bottom: 6px;
            font-size: 20px;
            color: #333333;
          }
        }
      }
      .flex4{
        margin-right: 30px;
        flex: 4;
      }
      .flex2{
        flex: 2;
      }
    }
  }
  .absorb-l-cont{
    padding: 20px;
    height: 315px;
    overflow-y: auto;
    .absorb-l-cont-item{
      .absorb-l-cont-item-tit{
        font-weight: bold;
      }
      .absorb-l-cont-item-list{
        padding-left: 50px;
        margin-bottom: 20px;
        .absorb-l-cont-item-list-item{
          margin-top: 20px;
          .absorb-l-cont-item-list-item-progress{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .absorb-l-cont-item-list-item-progress-nr{
              margin-left: 16px;
              width: 240px;
            }
          }
        }
      }
    }
  }
  .absorb-r-cont{
    padding: 20px;
    height: 315px;
    overflow-y: auto;

    .absorb-r-cont-item{
      margin-bottom: 16px;
      .absorb-r-cont-item-name{
        margin-bottom: 5px;
      }
      .absorb-r-cont-item-progress{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .absorb-r-cont-item-progress-nr{
          margin-left: 16px;
          width: 210px;
        }
      }
    }
  }
  .table{
    margin-top: 20px;
  }
  .cont-s{
    display: flex;
    justify-content: flex-start;
    >div{
      flex: 1;
      margin-right: 16px;
    }
    >div:last-child{
      margin-right: 0;
    }
  }
  .cont-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
    .cont-top-r{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >div{
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .legend{
        margin-right: 5px;
        display: inline-block;
        width: 14px;
        height: 14px;
        background: #4A79FF;
        border-radius: 1px;
      }
      .legend_g{
        background: #5AB4BF;
      }
    }
  }
  .mt10{
    margin-top: 10px;
  }
  .mt20{
    margin-top: 20px;
  }
  .tit{
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  .progress-bj{
    flex: 1;
    height: 10px;
    background: #EEEEEE;
    border-radius: 5px;
    position: relative;
    .progress-val{
      width: 70%;
      height: 10px;
      background: #4A79FF;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .page{
    margin-top: 20px;
    text-align: center;
  }

}
.no-data{
  padding: 100px 0;
  font-size: 14px;
  text-align: center;
  background-color: #FFFFFF;
}
</style>
