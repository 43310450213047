<template>
<div class="material-category">
  <div class="material-category-btn" @click="addData">添加题库</div>
  <Material :isApply="0" :siteId="siteId"></Material>
  <Modal
      v-model="activeMoreModal"
      title="添加题库"
      @on-cancel="cancelActiveMore"
      width="1000px"
      :footer-hide="true"
  >
    <Material :isApply="1" :siteId="siteId"></Material>
  </Modal>
</div>
</template>

<script>
import Material from './material';
export default {
  name: "materialCategory",
  data(){
    return {
      siteId:'',
      activeMoreModal:false
    }
  },
  created(){
    if (this.$route.params.id){
      this.siteId = this.$route.params.id;
      console.log(this.siteId,'this.siteId')
    }
  },
  components:{
    Material
  },
  methods:{
    cancelActiveMore(){
      this.activeMoreModal = false;
    },
    addData(){
      this.activeMoreModal = true;
    }
  }
}
</script>

<style scoped lang="scss">
.material-category{
  font-size: 14px;
  color: #333333;
  .material-category-btn{
    width: 96px;
    height: 28px;
    background: #5782FF;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
  }
}
</style>
