import { render, staticRenderFns } from "./attestationCategory.vue?vue&type=template&id=6d39b666&scoped=true&"
import script from "./attestationCategory.vue?vue&type=script&lang=js&"
export * from "./attestationCategory.vue?vue&type=script&lang=js&"
import style0 from "./attestationCategory.vue?vue&type=style&index=0&id=6d39b666&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d39b666",
  null
  
)

export default component.exports