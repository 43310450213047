<template>
<div>
  <div class="table">
    <Table :columns="columns" :data="data" :loading="loading"></Table>
  </div>
  <div class="page">
    <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
  </div>
</div>
</template>

<script>
export default {
  name: "projectList",
  data(){
    return {
      columns:[
        {
          title: '项目ID',
          key: 'id'
        },
        {
          title: '项目名称',
          key: 'name'
        },
        {
          title: '项目类型',
          key: 'category.name',
          render:(h,params)=>{
            return h('span',params.row.category.name);
          }
        },
        {
          title: '项目状态',
          key: 'status',
          render:(h,params)=>{
            return h('span',this.statusObj[params.row.status]);
          }
        },
        {
          title: '操作',
          key: 'op',
          render:(h,params)=>{
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.goDetail(params.row)
                  }
                }
              }, '查看'),
            ]);
          }
        },
      ],
      data:[],
      total:0,
      page:1,
      pageSize:10,
      loading:false,
      statusObj:{},
      siteId:'',
    }
  },
  created(){
    this.siteId = this.$route.params.id;
    this.getList();
  },
  methods:{
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
      };
      if(this.siteId){
        params.site_id = this.siteId;
      }
      this.loading = true;
      this.api.production.projects(params).then((res)=>{
        this.loading = false;
        this.data = res.list;
        this.total = Number(res.count);
        this.statusObj = res.statuses;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    goDetail(data){ //查看
      this.$router.push({
        name: 'project-detail',
        params: {
          id: data.id,
          tab: 'info'
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page{
  margin-top: 20px;
  text-align: right;
}
</style>
