<template>
  <div class="super-list">
    <div class="search">
      <Input class="search-input" v-model="keyword" :placeholder="$t('common_search_keyword')" clearable search :enter-button="true"
        @on-search="changeKeyword" style="width: 200px;" />
    </div>
    <div class="search-box">
      <div class="search-item">
        <div class="name">
          一级分类:
        </div>
        <div class="cont">
          <div class="values">
            <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList"
              :key="item.id + 'category'" @click="changeSearch(item, 'curCategoryId')">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="search-item" v-if="secondCategoryList.length > 1">
        <div class="name">
          二级分类:
        </div>
        <div class="cont">
          <div class="values">
            <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList"
              :key="item.id + 'secondCategory'" @click="changeSearch(item, 'cursecondCategoryId')">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="table">
      <Table border :columns="columns" :data="dataList"></Table>
    </div>
    <div class="page">
      <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total
        show-sizer show-elevator></Page>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "list",
  data() {
    return {
      // 一级分类id
      curCategoryId: "-1",
      // 一级分类数据
      categoryList: [],
      // 二级分类数据
      secondCategoryList: [],
      // 二级分类id
      cursecondCategoryId: "-1",
      keyword: '',
      columns: [
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: this.$t('codekey_list_active_category'),
          render: (h, params) => {
            return h('p', {
              // props: {
              //   to: {
              //     name: 'cdkey-detail',
              //     params: {
              //       id: params.row.id,
              //       tab:'list'
              //     }
              //   }
              // },
              // style:{
              //   color:'#2db7f5',
              // }
            }, (params.row.category ? params.row.category.name : '不限') + '-' + (params.row.secondCategory ? params.row.secondCategory.name : '不限'));
          }
        },
        {
          title: this.$t('codekey_list_code_timelong'),
          key: 'timelong',
          render: (h, params) => {
            return h('div', {}, this.timelongsObj[params.row.timelong]);
          }
        },
        {
          title: this.$t('codekey_list_surplus_num'),
          key: 'amount',
          render: (h, params) => {
            return h('div', {}, params.row.amount - params.row.count);
          }
        },
        {
          title: this.$t('codekey_list_active_user'),
          key: 'used_count',
        },
        {
          title: this.$t('codekey_list_unactive_user'),
          key: 'timelong',
          render: (h, params) => {
            return h('div', {}, params.row.count - params.row.used_count);
          }
        },
        {
          title: this.$t('codekey_list_active_rate'),
          key: 'rate',
          render: (h, params) => {
            return h('div', {}, params.row.count > 0 && params.row.used_count > 0 ? Math.floor(params.row.used_count / params.row.count * 100) + '%' : '0%');
          }
        },
        {
          title: this.$t('codekey_list_status'),
          key: 'status',
          render: (h, params) => {
            return h('div', {}, this.statusesObj[params.row.status]);
          }
        },
        {
          title: this.$t('codekey_manage_create_time'),
          key: 'created_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
              new Date(+para.row.created_at * 1000), 'yyyy-MM-dd hh:mm'
            )
            );
          }
        },
        {
          title: this.$t('codekey_manage_update_time'),
          key: 'updated_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
              new Date(+para.row.updated_at * 1000), 'yyyy-MM-dd hh:mm'
            )
            );
          }
        },
        {
          title: this.$t('codekey_manage_op'),
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h("Button",{
                props:{
                  type:"primary",
                  size:"small"
                },
                on:{
                  click:()=>{
                    this.viewDetails(params.row);
                  }
                }
              },"查看明细")
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small',
              //     disabled:params.row.status == 2 ? true : false
              //   },
              //   style: {
              //     marginRight: '5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.addUser(params.row)
              //     }
              //   }
              // }, this.$t('codekey_list_add_user')),
            ]);
          }
        }
      ],
      dataList: [],
      total: 0,
      page: 1,
      pageSize: 10,
      statusesObj: {},
      timelongsObj: {},
      curData: '',
      addUserModal: false,
      userColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: this.$t('codekey_list_name'),
          key: 'nickname'
        },
        {
          title: this.$t('codekey_list_company'),
          key: 'id',
          render: (h, params) => {
            return h('div', {}, params.row.site.name);
          }
        },
      ],
      userDataList: [],
      userPage: 1,
      userPageSize: 10,
      userTotal: 0,
      userKeyword: '',
      userCategory: '',
      userMode: this.$t('codekey_list_select_user'),
      modeList: [this.$t('codekey_list_select_user'), this.$t('codekey_list_issue')],
      allUserData: [],
      count: '',

    }
  },
  props: {
    siteId: {
      type: String,
      default: ''
    },
    isManager: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.cdkeyForm(); //获取时长与状态
    this.getList(); //获取激活码列表
    this.getCategoryList();// 获取分类数据
  },
  methods: {
    changeKeyword() {
      this.page = 1;
      this.getList();
    },
    changePage(page) {
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    add() {  //新增激活分类
      this.addCategory = true;
    },
    getList() {  //获取列表数据
      let params = {
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.keyword,
        site_id: this.$route.params.id,
        // 一级分类id
        category_id: this.curCategoryId,
        // 二级分类id
        second_category_id: this.cursecondCategoryId,
      };
      this.api.codekey.cdkeyList(params).then((res) => {
        this.dataList = res.list;
        this.total = Number(res.count);
      })
    },
    cdkeyForm() {
      this.api.codekey.cdkeyForm().then((res) => {
        this.statusesObj = res.statuses;
        this.statusesList = [];
        for (let name in res.statuses) {
          this.statusesList.push({
            label: res.statuses[name],
            value: name
          })
        }

        this.timelongsObj = res.timelongs;
        this.timelongsList = [];
        for (let name in res.timelongs) {
          this.timelongsList.push({
            label: res.timelongs[name],
            value: name
          })
        }
      })
    },
    // 获取一级分类信息
    getCategoryList() {
      const data = {
        page: 1,
        pageSize: 10,
        category_id: this.curCategoryId,
        category_second_id: this.cursecondCategoryId,
        is_hide_children: 1,
        type:"0"
      }
      this.api.dataset.categoryForm(data).then((res) => {
        this.categoryList = [
          {
            name: "全部",
            id: "-1"
          },
          ...res.categoryFirstList
        ];
        //二级标签
        this.secondCategoryList = [
          {
            name: '全部',
            id: '-1'
          },
          ...res.categorySecondList
        ];
      });
    },
    // 当分类改变时触发
    changeSearch(data, name) {
      if (name == 'curCategoryId') {
        this.cursecondCategoryId = '-1';
        this.curThirdCategoryId = '-1';
      } else if (name == 'cursecondCategoryId') {
        this.curThirdCategoryId = '-1';
      }
      this[name] = data.id;
      this.page = 1;
      this.getCategoryList();
      this.getList();
    },
    // 查看明细
    viewDetails(row){
      if(this.isManager){
        this.$router.push({
          path:"/cdkey/list",
          query:{
            tabId:2,
            cdkey:row.id
          }
        });
      }else{
        this.$router.push({
          path:"/cdkey/managelist",
          query:{
            tabId:2,
            cdkey:row.id
          }
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.super-list {
  //margin: 20px;
  //padding:20px;
  //background: #FFFFFF;
  //box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  //border-radius: 4px;


  .top>button {
    margin-right: 20px;
  }

  .search {
    display: flex;
    justify-content: flex-end;
    height: 0;
    .search-input{
      transform: translateY(-58px);
    }
  }

  .search-box {
    margin-top: 20px;
    margin-bottom: 20px;

    .search-item {
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name {
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }

      .cont {
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values {
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div {
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }

          >div.active {
            background-color: #2d8cf0;
            color: #FFFFFF;
            border-radius: 4px;
          }
        }

        .more {
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;

          .more-icon {
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
}

.table {
  margin-top: 20px;
}

.page {
  margin-top: 20px;
  text-align: right;
}

.add-container {
  .tit {
    margin-bottom: 20px;
    padding: 0 20px;
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    >p {
      margin-right: 10px;

      >span {
        color: #2d8cf0;
      }
    }

    >p:nth-child(2) {
      font-size: 14px;
    }
  }

  .category-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    >div {
      margin-right: 10px;
      padding: 0px 10px;
      cursor: pointer;
    }

    >div.active {
      background-color: #2d8cf0;
      color: #FFFFFF;
      border-radius: 4px;
    }
  }

  .mode-cont {
    margin-top: 10px;
    padding: 20px;
    border: 1px solid #dcdee2;

    .mode-cont-tit {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .mode-cont-tit>div:nth-child(1) {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      >p {
        margin-left: 10px;
      }
    }
  }
}</style>
<style lang="scss">.company-h .ivu-form-item {
  margin-bottom: 10px !important;
}</style>
