<template>
  <div class="detail">
    <div class="layout-content">
      <Row>
        <i-col span="2">
          <div class="head-img">
            <!-- <Upload
              :action="constant.URL + '/uploadfile/upload'"
              :data="{
                type: 3,
              }"
              accept=".jpg,.png,.jpeg,.gif"
              :headers="{
                'access-token': Cookies.get('liteAccessToken'),
              }"
              :on-success="fileSuccess"
              :show-upload-list="false"
            >
              <div class="head-img-info">
                <img v-if="siteDetail.logo" :src="siteDetail.logo" />
                <p v-else-if="siteDetail.name">
                  {{ siteDetail.name && siteDetail.name[0].toUpperCase() }}
                </p>
                <p class="head-img-change">修改</p>
              </div>
            </Upload> -->
            <div class="head-img-info">
              <img v-if="siteDetail.logo" :src="siteDetail.logo" />
            </div>
          </div>
        </i-col>
        <i-col span="22">
          <Row>
            <i-col span="24" class="project-detail-info">
              <div class="info-item">
                <strong
                  style="
                    font-size: 16px;
                    max-width: 600px;
                    display: inline-block;
                    padding-left: 10px;
                  "
                  >{{ siteDetail.name }}</strong
                >
                <Button type="primary" @click="showEditSite">编辑</Button>
              </div>
            </i-col>
          </Row>
          <Row :gutter="20" style="font-size: 14px; margin: 10px 0">
            <i-col span="4">组织ID : {{ siteDetail.id }}</i-col>
            <i-col span="4"
              >状态 : {{ site_statuses[siteDetail.status] }}</i-col
            >
            <i-col span="4">类型 : {{ site_types[siteDetail.type] }}</i-col>
            <i-col span="4"
              >服务类型 :
              {{ site_service_types[siteDetail.service_type] }}</i-col
            >
            <i-col span="8" v-if="siteDetail.created_at"
              >创建时间: {{ siteDetail.created_at }}</i-col
            >
          </Row>
          <Row :gutter="20" style="font-size: 14px; margin: 10px 0">
            <i-col span="16">备注 : {{ siteDetail.intro }}</i-col>
            <i-col span="8" v-if="siteDetail.updated_at"
              >更新时间 : {{ siteDetail.updated_at }}</i-col
            >
          </Row>
        </i-col>
      </Row>
    </div>
    <div class="tab-cont">
      <div class="tab-top">
        <div class="tab-top-l">
          <div
            :class="currentIndex == index ? 'active' : ''"
            v-for="(item, index) in tabList"
            :key="item.id"
            @click="changeTab(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <!--        <Button type="primary">生成邀请链接</Button>-->
      </div>
      <component
        :is="tabList[currentIndex].componentName"
        :isSuper="isSuper"
        :detail="siteDetail"
        :isManager="isManager"
      ></component>
    </div>
    <Modal v-model="siteModal" title="编辑组织">
      <Form
        ref="formValidate"
        :model="formItem"
        :rules="formRule"
        :label-width="80"
      >
        <FormItem label="名称" prop="name">
          <Input
            v-model="formItem.name"
            placeholder="Enter something..."
          ></Input>
        </FormItem>
        <!--        <FormItem label="状态" prop="status">-->
        <!--          <Select v-model="formItem.status">-->
        <!--            <Option :value="item.value" v-for="item in statusList" :key="item.value">{{ item.label }}</Option>-->
        <!--          </Select>-->
        <!--        </FormItem>-->
        <FormItem label="备注">
          <Input
            v-model="formItem.remark"
            type="textarea"
            placeholder="Enter something..."
          />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="cancel">取消</Button>
        <Button type="primary" @click="confirm" :loading="editLoading"
          >确定</Button
        >
      </div>
    </Modal>

    <!-- 组织编辑抽屉 -->
    <el-drawer
      title="编辑"
      :visible.sync="editDrawer"
      :size="800"
    >
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">编辑</div>
        <div class="drawer-title-btns">
          <Button class="cancel" style="margin-right: 20px" @click="cancel"
            >取消</Button
          >
          <Button type="primary" @click="ok" :loading="addBtnLoading"
            >确定</Button
          >
        </div>
      </div>
      <div style="padding: 20px;">
        <el-form
        :model="formItem"
        ref="formCustom"
        label-width="80px"
        style="padding: 0 50px"
        :rules="ruleValidate"
      >
        <el-form-item :label="$t('site_table_name')" prop="name">
          <el-input v-model="formItem.name" maxlength="20" :placeholder="$t('site_add_name_tip')"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-radio-group v-model="formItem.type">
            <el-radio :label="index" v-for="(item,index) in typesObj" :key="index">{{ item }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="地域" prop="cityId">
          <el-cascader :options="cityList" v-model="formItem.cityId" :props="modalOptionProps" clearable></el-cascader>
        </el-form-item>
        <el-form-item label="简介" prop="intro">
          <el-input
            v-model="formItem.intro"
            type="textarea"
            :placeholder="$t('site_add_name_tip')"
          ></el-input>
        </el-form-item>
        <el-form-item label="LOGO" prop="logo">
          <el-upload
            :action="constant.URL + '/uploadfile/upload'"
            :data="{
              type: 3,
            }"
            accept=".jpg,.png,.jpeg,.gif"
            :headers="{
              'access-token': Cookies.get(constant.tokenName),
            }"
            :on-success="uploadLogoSuccess"
            :show-file-list="false"
          >
            <div class="upload-btn" v-if="!formItem.logo">
              <Icon type="ios-add" :size="50" />
            </div>
            <div class="dingding-img" v-else>
              <img :src="formItem.logo" height="60" />
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      </div>

    </el-drawer>
  </div>
</template>
<script>
import util from "@/utils/tools.js";
import Cookies from "js-cookie";
import UserList from "../../../user/components/userList";
import ProjectList from "../mySite/projectList";
import Invitationlist from "../mySite/invitationList.vue";
import RechargeList from "../mySite/rechargeList";
import SiteStatistics from "./siteStat";
import CategoryManage from "./categoryManage";
import SiteCategoryManage from "./siteCategoryManage";
export default {
  name: "detail",
  data() {
    const validateCity = (rule, value, callback)=>{
      if (!this.formItem.cityId.length) {
        return callback(new Error('请选择地域'));
      }else{
        callback();
      }
    }
    return {
      addBtnLoading: false,
      Cookies: Cookies,
      editDrawer: false,
      siteDetail: {},
      page: 1,
      pageSize: 10,
      tabList: [
        {
          name: "成员列表",
          id: 1,
          componentName: "UserList",
          tab: "user",
        },
        // {
        //   name:'项目列表',
        //   id:2,
        //   componentName:'ProjectList',
        //   tab:'project'
        // },
        {
          name: "邀请链接列表",
          id: 3,
          componentName: "Invitationlist",
          tab: "invitation",
        },
        {
          name: this.$t("codekey_detail_recharge_list"),
          id: 4,
          componentName: "RechargeList",
          tab: "recharge",
        },
        {
          name: "组织统计",
          id: 5,
          componentName: "SiteStatistics",
          tab: "statistics",
        },
      ],
      currentIndex: 0,
      siteModal: false,
      formItem: {},
      typesObj: {},
      formRule: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      editLoading: false,
      siteId: "",
      site_statuses: {},
      site_types: {},
      site_service_types: {},
      ruleValidate: {
        name: [
          { required: true, message: this.$t('site_add_name_tip'), trigger: 'blur' }
        ],
        cityId:[
          { required: true, message: '请选择地域', validator: validateCity, },
        ],
        intro: [{ required: false, message: "请输入简介", trigger: "blur" }],
        logo: [
          { required: false, message: "请上传LOGO", trigger: "change" },
        ],
      },
      service_typesArr: [],
      cityList: [],
      modalOptionProps: {
        value: "id",
        label: "name",
        children: "children",
      },
    };
  },
  props: {
    isSuper: {
      type: Boolean,
      default: false,
    },
    isManager: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UserList,
    ProjectList,
    Invitationlist,
    RechargeList,
    SiteStatistics,
    CategoryManage,
    SiteCategoryManage,
  },
  created() {
    if (this.isSuper) {
      this.tabList.push({
        name: "题库管理",
        id: 6,
        componentName: "CategoryManage",
        tab: "categoryManage",
      });
    } else {
      this.tabList.push({
        name: "题库管理",
        id: 7,
        componentName: "SiteCategoryManage",
        tab: "siteCategoryManage",
      });
    }
    if (this.$route.params.id) {
      this.siteId = this.$route.params.id;
    }
    if (this.$route.params.tab) {
      this.currentIndex = this.tabList.findIndex((item) => {
        return item.tab == this.$route.params.tab;
      });
    }
    this.getDetail();
    this.getPlaceList();
    this.getSiteForm();
  },
  methods: {
    getDetail() {
      let params = {
        // id:1
      };
      if (this.siteId) {
        params.site_id = this.siteId;
      }
      this.api.site.siteDetail(params).then((res) => {
        this.siteDetail = res.info;
        this.siteDetail.created_at = util.timeFormatter(
          new Date(+res.info.created_at * 1000),
          "yyyy-MM-dd hh:mm"
        );
        this.siteDetail.updated_at = util.timeFormatter(
          new Date(+res.info.updated_at * 1000),
          "yyyy-MM-dd hh:mm"
        );

        this.site_statuses = res.statuses;
        this.site_types = res.types;
        this.site_service_types = res.service_types;
      });
    },
    changeTab(data) {
      if (this.$route.params.tab == this.tabList[data].tab) {
        return;
      }
      this.currentIndex = data;
      console.log(
        this.$route.name,
        this.siteId,
        this.tabList[this.currentIndex].tab,
        "this.$route.name"
      );
      let option = {
        name: this.$route.name,
        params: {
          tab: this.tabList[this.currentIndex].tab,
        },
      };
      if (this.siteId) {
        option.params.id = this.siteId;
      }
      this.$router.push(option);
    },
    showEditSite() {
      // this.formItem.name = this.siteDetail.name;
      // this.formItem.status = this.siteDetail.status + '';
      // this.formItem.remark = this.siteDetail.intro;
      // this.siteModal = true;
      const arr = [];
      if (this.siteDetail.province_id > 0) {
        arr.push(this.siteDetail.province_id);
        if (this.siteDetail.city_id) {
          arr.push(this.siteDetail.city_id);
        }
      }
      console.warn("地域信息",arr);
      this.formItem = { ...this.siteDetail, cityId: arr };
      this.editDrawer = true;
    },
    confirm() {
      this.$refs["formCustom"].validate((valid) => {
        if (valid) {
          let params = {
            site_id: this.siteDetail.id,
            name: this.formItem.name,
            intro: this.formItem.remark,
          };
          this.editLoading = true;
          this.api.site
            .siteUpdate(params)
            .then(() => {
              this.editLoading = false;
              this.siteModal = false;
              this.getDetail();
            })
            .catch(() => {
              this.editLoading = false;
            });
        }
      });
    },
    cancel() {
      this.editDrawer = false;
    },
    // 过滤非数字
    numberFilter(e) {
      const reg = /\D/gi;
      const value = e.replace(reg, "");
      this.formItem.default_category_id = value;
    },
    getSiteForm() {
      this.api.site.siteForm({}).then((res) => {
        this.typesObj = res.types || {};
        const data = res.service_types || {};
        this.service_typesArr = [];
        Object.keys(data).forEach((key) => {
          this.service_typesArr.push({
            value: key,
            label: data[key],
          });
        });
      });
    },
    fileSuccess(response) {
      //上传文件成功
      this.siteDetail.logo = response.data.info.upload_path;

      let params = {
        logo: this.siteDetail.logo,
      };
      if (this.siteId) {
        params.site_id = this.siteId;
      }

      this.api.site.siteUpdateLogo(params).then(() => {
        this.$Message.success("修改成功");
      });
    },
    getPlaceList() {
      //获取地域
      this.api.site.placeList().then((res) => {
        this.cityList = res.list;
      });
    },
    uploadLogoSuccess(response) {
      this.formItem.logo = response.data.info.upload_path;
      this.$refs.formCustom.validateField("logo");
    },
    ok() {
      this.$refs.formCustom.validate((valid) => {
        if (valid) {
          let data = {
            site_id: this.formItem.id,
            intro: this.formItem.intro,
            logo: this.formItem.logo,
            province_id:this.formItem.cityId[0],
            city_id:this.formItem.cityId[1],
            name:this.formItem.name,
            type:this.formItem.type,
          };
          // 按钮加载样式
          this.addBtnLoading = true;
          // 如果有id发起请求
          if (this.formItem.id) {
            this.api.site
              .siteUpdate(data)
              .then(() => {
                this.addBtnLoading = false;
                this.editDrawer = false;
                this.getDetail();
              })
              .catch(() => {
                this.addBtnLoading = false;
              });
          }
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.detail {
  .edit-notes {
    margin-left: 5px;
    cursor: pointer;
    color: #2d8cf0;
  }

  .layout-content {
    margin: 20px;
    padding: 20px;
    background: #ffffff;
    min-height: 100px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);

    .project-detail-info .info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .project-detail-info .info-item button {
      margin-bottom: 5px;
    }

    .project-detail-info .info-item button + button {
      margin-left: 5px;
    }

    .info-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .tit-tag {
        padding: 5px 10px;
        text-align: center;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    .head-img {
      width: 80px;
      height: 80px;
      border-radius: 80px;
      line-height: 80px;
      background-color: #ccc;
      text-align: center;
      font-size: 55px;
      color: #ffffff;

      .head-img-info {
        position: relative;
        width: 80px;
        height: 80px;
        border-radius: 80px;
        overflow: hidden;

        > img {
          width: 100%;
          height: 100%;
          border-radius: 80px;
        }

        .head-img-change {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #ffffff;
          background-color: rgba(45, 140, 240, 0.9);
          width: 100%;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }

  .tab-cont {
    margin: 0 20px 20px 20px;
    padding: 20px;
    background: #ffffff;

    //min-height: 180px;
    .tab-top {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //border:1px solid #dcdee2;
      text-align: center;
      height: 46px;
      overflow: hidden;
      font-size: 14px;
      cursor: default;

      .tab-top-l {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #dcdee2;
        height: 100%;
        border-radius: 4px;

        > div {
          width: 150px;
          border-right: 1px solid #dcdee2;
          cursor: pointer;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        > div:last-child {
          border-right: none;
        }

        > div.active {
          background-color: #2d8cf0;
          color: #ffffff;
        }
      }
    }
  }
}

.upload-btn {
  width: 120px;
  height: 120px;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
