<template>
  <div class="data-list">
    <div class="search">
      <div style="position: relative">
        <Spin fix v-if="loading"></Spin>
        <div v-else class="search-item">
          <div class="name">
            {{$t('trainingcamp_exam_create_category_first')}}:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeFirstCategory(item)">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="position: relative">
        <Spin fix v-if="secondLoading"></Spin>
        <div class="search-item" v-if="secondCategoryList.length">
          <div class="name">
            {{$t('trainingcamp_exam_create_category_second')}}:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSecondSearch(item)">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="material-cont" >
      <Table border :columns="columns" :data="materialList"></Table>

    </div>
    <!--    <div class="page">-->
    <!--      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize" :pageSizeOpts="[9,18,27,36]"></MyPage>-->
    <!--    </div>-->
    <Modal
        v-model="aliasModal"
        title="添加别名"
    >
      <div>
        <span style="width: 100px;text-align: right;display: inline-block">别名：</span>
        <Input v-model="alias" placeholder="请输入别名" style="width: 300px" />
      </div>
      <div slot="footer">
        <Button @click="aliasModal=false">取消</Button>
        <Button type="primary" @click="confirmAlias()">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>

export default {
  name: "dataList",
  data(){
    return {
      columns: [
        {
          title: this.$t('project_create_data_table_id'),
          key: 'id',
          width: 100,
        },
        {
          title: '题库名称',
          key: 'name'
        },
        {
          title: '关联图谱',
          key: 'name',
          render:(h,params)=>{
            return h('span',params.row.map ? params.row.map.name + '('+params.row.map.id+')':'')
          }
        },
        {
          title: '类型',
          width: 140,
          render:(h,params)=>{
            return h('span',params.row.is_free == '1' ? '免费' : '收费')
          }
        },
        {
          title: '自动开通',
          width: 140,
          render:(h,params)=>{
            return h('span',params.row.auto_open == '1' ? '是' : '否')
          }
        },
        // {
        //   title: this.$t('trainingcamp_exam_create_category_count'),
        //   key: 'data_count'
        // },

      ],
      dataList: [
      ],
      loading:false,
      page:1,
      pageSize: 10,
      keyword:'',
      total:0,
      categoryList:[],  //一级分类
      curCategoryId:'-1', //当前分类
      secondCategoryList:[],  //二级分类
      cursecondCategoryId:'-1', //当前二级分类
      tableLoading:false,
      secondLoading:false,
      materialList:[],
      aliasModal:false,
      alias:'',
      curData:{},
      buttonNr:{
        title: this.$t('project_create_data_table_op'),
        key: 'action',
        width: 200,
        align: 'center',
        render: (h, params) => {
          return h('div', [
            // this.isApply == '0' ? h ('Button', {
            //   props: {
            //     type: 'primary',
            //     size: 'small'
            //   },
            //   style: {
            //     marginRight: '5px'
            //   },
            //   on: {
            //     click: () => {
            //       this.appendAlias(params.row)
            //     }
            //   }
            // }, '更改名称') : '',
            this.isApply == '1' && (params.row.open_status ==0 || params.row.open_status ==2) ?  h('Button', {
              props: {
                type: 'primary',
                size: 'small'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  // this.create(params.row)
                  this.curData = params.row;
                  this.changeStatus(1);
                }
              }
            }, '开通') : '',
            (this.isApply == '1' && params.row.open_status == 1) || this.isApply == '0' ? h('Button', {
              props: {
                size: 'small'
              },
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  // this.create(params.row)
                  this.curData = params.row;
                  this.changeStatus(0);
                }
              }
            }, '取消开通') : '',
          ]);
        }
      }
    }
  },
  props:{
    isApply:{
      type:Number,
      default:0,
    },
    number:{
      type:Number,
      default:0,
    },
    siteId:{
      type:String,
      default:'',
    },
    unButton:{
      type:Boolean,
      default:false,
    }
  },
  watch:{
    siteId:{
      handler(newV){
        this.getList();
      },
      immediate:true,
    },
    unButton:{
      handler(newV){
        if(!newV){
          this.columns.push(this.buttonNr);
        }
      },
      immediate:true,
    }
  },
  created(){

  },
  methods:{
    getList(){
      let data = {
        keyword:this.keyword,
        type:1,
        is_apply:this.isApply,
        site_id:this.siteId,
        is_show_has_third:1,
      };
      this.loading = true;
      this.api.dataset.categoryForm(data).then((res)=>{
        this.loading = false;
        //一级标签
        this.categoryList = [
          {
            name:'全部',
            id:-1
          },
          ...res.categoryFirstList
        ];
        this.curCategoryId = this.categoryList[0].id ;

        this.secondCategoryList = [
          {
            name:'全部',
            id:-1
          },
          ...res.categorySecondList
        ];
        this.cursecondCategoryId = this.secondCategoryList[0].id;

        this.materialList = res.categoryThirdList;

      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeFirstCategory(data){
      this.curCategoryId = data.id;
      this.getSecondList();
    },
    getSecondList(){
      let data = {
        keyword:this.keyword,
        category_id:this.curCategoryId,
        type:1,
        is_apply:this.isApply,
        site_id:this.siteId,
        is_show_has_third:1,
        // second_category_id:this.cursecondCategoryId
      };
      this.secondLoading = true;
      this.api.dataset.categoryForm(data).then((res)=>{
        this.secondLoading = false;
        //一级标签
        this.secondCategoryList = [
          {
            name:'全部',
            id:-1
          },
          ...res.categorySecondList
        ];
        this.cursecondCategoryId = this.secondCategoryList[0].id;

        this.materialList = res.categoryThirdList;


      }).catch((err)=>{
        this.secondLoading = false;
      })
    },
    changeSecondSearch(data){
      this.cursecondCategoryId = data.id;
      this.getThreeList();
    },
    getThreeList(){
      let data = {
        keyword:this.keyword,
        category_id:this.curCategoryId,
        category_second_id:this.cursecondCategoryId,
        type:1,
        is_apply:this.isApply,
        site_id:this.siteId,
        is_show_has_third:1,
      };
      this.tableLoading = true;
      this.api.dataset.categoryForm(data).then((res)=>{
        this.tableLoading = false;
        //三级标签
        this.materialList = res.categoryThirdList;


      }).catch((err)=>{
        this.tableLoading = false;
      })
    },
    search(){
      this.getThreeList();
    },
    create(data){
      this.$emit('create',data.id)
    },
    activeMore(){
      this.$emit('activeMore')
    },
    changePage(page){
      this.page = page;
      this.getThreeList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getThreeList();
    },
    appendAlias(data){  //添加别名
      this.curData = data;
      this.aliasModal = true;
      this.alias = this.curData.name;
    },
    confirmAlias(data){ //确定别名
      let params = {
        category_id:this.curData.id,
        site_id:this.siteId,
        rename:this.alias
      };
      this.api.dataset.datasetCategoryToSiteVerify(params).then((res)=>{
        this.$Message.success('修改成功');
        this.aliasModal = false;
        // this.handleCategoryModal = false;
        this.getThreeList();
      })


    },
    changeStatus(data){

      let params = {
        category_id:this.curData.id,
        site_id:this.siteId,
        check:data
      };
      this.api.dataset.datasetCategoryToSiteVerify(params).then((res)=>{
        this.$Message.success('更新成功');
        this.getThreeList();
      })

    },
  }
}
</script>

<style scoped lang="scss">
.search{
  margin-top: 20px;
  font-size: 14px;
  color: #333333;
  .search-item{
    display: flex;
    justify-content: flex-start;
    font-size: 14px;

    .name{
      padding: 5px 0;
      width: 75px;
      text-align: right;
      font-weight: bold;
    }
    .cont{
      padding: 5px 0;
      margin-left: 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;

      .values{
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        transition: max-height 0.3s linear;
        overflow: hidden;

        >div{
          //margin: 0 10px 10px 5px;
          margin-right: 20px;
          margin-bottom: 10px;
          cursor: pointer;
        }
        >div.active{

          font-weight: bold;
          color: #4A79FF;
        }
      }
      .more{
        padding: 5px 0;
        width: 80px;
        text-align: right;
        cursor: pointer;
        .more-icon{
          transition: transform 0.3s linear;
        }
      }
    }
  }
}
.material-cont{
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  >div{
    flex: 1;
  }

  .material-item{
    margin-right: 20px;
    height: 126px;
    background: #F6F7FA;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .material-status{
      position: absolute;
      right: 0;
      top: 0;
      >p{
        width: 69px;
        height: 25px;
        border-radius: 4px;
        text-align: center;
        line-height: 25px;
      }

      .purple{
        background: #F0EDFF;
        color: #757BF2;
      }
      .yellow{
        background: #FAEFD0;
        color: #FE9200;
      }
    }

    .material-item-l{
      margin-right: 16px;
      width: 90px;
      height: 126px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      >img{
        width: 100%;
        height: 100%;
      }
      >p{
        width: 90px;
        height: 28px;
        background: #F0EDFF;
        border-radius: 6px 6px 0 0;
        font-size: 14px;
        color: #757BF2;
        text-align: center;
        line-height: 28px;
        position: absolute;
        left: 0;
        bottom:0;
      }
    }
    .material-item-r{
      margin-right: 16px;
      flex: 1;
      width: 0;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100px;

      .material-item-r-info-tit{
        //margin-bottom: 2px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .material-item-r-info-auth{
        //margin-bottom: 10px;
      }

      .material-item-r-info{
        font-size: 14px;
        color: #666666;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .material-item-r-info-val{
          >span{
            font-weight: bold;
          }
        }
        .material-item-r-info-btn-cont{
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .material-item-r-btn{
            width: 70px;
            height: 34px;
            background: #FFFFFF;
            border: 1px solid #E9E9E9;
            border-radius: 4px;
            color: #333333;
            text-align: center;
            line-height: 34px;
            cursor: pointer;
          }
          .active{
            background: #4A79FF;
            color: #FFFFFF;
          }
          .activeing{
            border: 1px solid #4A79FF;
            color: #4A79FF;
          }
          .actived{
            background: #EEEEEE;
            color: #999999;
          }
          .mr10{
            margin-right: 10px;
          }
        }
      }

    }
  }
}
.activate-more-btn{
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 40px;
  background: #4A79FF;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
  >img{
    margin-left: 8px;
  }
}
.page{
  margin: 20px 0;
  text-align: center;
}

</style>
