<template>
  <div class="data-list">
    <div class="search">
      <div style="position: relative">
        <Spin fix v-if="loading"></Spin>
        <div v-else class="search-item">
          <div class="name">
            {{$t('trainingcamp_exam_create_category_first')}}:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeFirstCategory(item)">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="position: relative">
        <Spin fix v-if="secondLoading"></Spin>
        <div class="search-item" v-if="secondCategoryList.length">
          <div class="name">
            {{$t('trainingcamp_exam_create_category_second')}}:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSecondSearch(item)">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="activate-more-btn" @click="activeMore" v-if="showMore">
      <p>开通更多题库</p>
      <img src="../../../../assets/images/new_icon/you.png" width="8" height="14">
    </div>
    <div class="material-cont" v-for="(item,index) in materialList" :key="index">
      <div v-for="(sItem,index) in number" :key="sItem">
        <div class="material-item" v-if="item[index]" :key="item[index].id">
          <div class="material-item-l">
            <img :src="item[index].map.book_img">
            <!--          <p>上次练习</p>-->
          </div>
          <div class="material-item-r">
            <div>
              <p class="material-item-r-info-tit">{{ item[index].name }}</p>
              <p class="material-item-r-info-auth">{{ item[index].map.book_author }}</p>
            </div>

            <div class="material-item-r-info">
              <div></div>
              <div class="material-item-r-info-btn-cont" v-if="isApply == 0">
                <div class="material-item-r-btn active mr10" @click="goMaterialCreate(item[index],1,2)">模考组卷</div>
<!--                <div class="material-item-r-btn active mr10" @click="goEvaluationCreate(item[index],1,12)">千人千卷</div>-->
<!--                <div class="material-item-r-btn" @click="goMaterialCreate(item[index],2,4)">练习组卷</div>-->
              </div>
              <div class="material-item-r-info-btn-cont" v-if="isApply == 1">
                <div class="material-item-r-btn active" v-if="item[index].open_status == '0'" @click="applyCategory(item[index])">申请开通</div>
                <div class="material-item-r-btn activeing" v-if="item[index].open_status == '2'">申请中...</div>
                <div class="material-item-r-btn actived" v-if="item[index].open_status == '1'">已开通</div>
              </div>
            </div>
          </div>
          <div class="material-status" v-if="isApply == 0">
            <p class="purple" v-if="item[index].is_free == 1">免费体验</p>
            <p class="yellow" v-else>已开通</p>
          </div>

        </div>
      </div>

    </div>
<!--    <div class="page">-->
<!--      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize" :pageSizeOpts="[9,18,27,36]"></MyPage>-->
<!--    </div>-->
  </div>
</template>

<script>


export default {
  name: "dataList",
  data(){
    return {
      columns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   align: 'center',
        // },
        {
          title: this.$t('project_create_data_table_id'),
          key: 'id',
        },
        {
          title: this.$t('trainingcamp_exam_create_category_name'),
          key: 'name'
        },
        {
          title: this.$t('trainingcamp_exam_create_category_count'),
          key: 'data_count'
        },
        {
          title: this.$t('project_create_data_table_op'),
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.create(params.row)
                  }
                }
              }, '创建'),
            ]);
          }
        }
      ],
      dataList: [
      ],
      loading:false,
      page:1,
      pageSize: 10,
      keyword:'',
      total:0,
      categoryList:[],  //一级分类
      curCategoryId:'-1', //当前分类
      secondCategoryList:[],  //二级分类
      cursecondCategoryId:'-1', //当前二级分类
      tableLoading:false,
      secondLoading:false,
      materialList:[],
    }
  },
  props:{
    showMore:{
      type:Boolean,
      default:false,
    },
    isApply:{
      type:Number,
      default:0,
    },
    number:{
      type:Number,
      default:0,
    },
    // 是否隐藏已通过的课程
    passed:{
      type:Boolean,
      default:false
    }
  },
  watch:{
    number:{
      handler(newV){
        if(newV){
          this.getList();
          if(newV == 3){
            this.pageSizeOpts = [9,18,27,36];
          }else{
            this.pageSizeOpts = [10,20,50,100];
          }

        }
      },
      immediate:true,
    }
  },
  created(){

  },
  methods:{

    getList(){
      let data = {
        keyword:this.keyword,
        type:1,
        is_apply:this.isApply,
        is_show_has_third:1
      };
      this.loading = true;
      this.api.dataset.categoryForm(data).then((res)=>{
        this.loading = false;
        //一级标签
        this.categoryList = [
          {
            name:'全部',
            id:-1
          },
          ...res.categoryFirstList
        ];
        this.curCategoryId = this.categoryList[0].id ;

        this.secondCategoryList = [
          {
            name:'全部',
            id:-1
          },
          ...res.categorySecondList
        ];
        this.cursecondCategoryId = this.secondCategoryList[0].id;

        this.materialList = [];
        let num = this.number;
        if(this.passed){
          res.categoryThirdList=res.categoryThirdList.filter(item=>item.open_status!="1");
        }
        for(let i=0;i<res.categoryThirdList.length;i+=num){
          this.materialList.push(res.categoryThirdList.slice(i,i+num));
        }
      }).catch(()=>{
        this.loading = false;
      })
    },
    handleMaterialList(){
      const arr=[];
      this.materialList.forEach(item=>{
        const itemArr=[];
        item.forEach((obj)=>{
          if(obj.open_status!="1"){
            itemArr.push(obj);
          }
        });
        arr.push(itemArr);
      });
      this.materialList=arr;
    },
    changeFirstCategory(data){
      this.curCategoryId = data.id;
      this.getSecondList();
    },
    getSecondList(){
      let data = {
        keyword:this.keyword,
        category_id:this.curCategoryId,
        type:1,
        is_apply:this.isApply,
        is_show_has_third:1
        // second_category_id:this.cursecondCategoryId
      };
      this.secondLoading = true;
      this.api.dataset.categoryForm(data).then((res)=>{
        this.secondLoading = false;
        //一级标签
        this.secondCategoryList = [
          {
            name:'全部',
            id:-1
          },
          ...res.categorySecondList
        ];
        this.cursecondCategoryId = this.secondCategoryList[0].id;

        this.materialList = [];
        let num = this.number;
        if(this.passed){
          res.categoryThirdList=res.categoryThirdList.filter(item=>item.open_status!="1");
        }
        for(let i=0;i<res.categoryThirdList.length;i+=num){
          this.materialList.push(res.categoryThirdList.slice(i,i+num));
        }
      }).catch((err)=>{
        this.secondLoading = false;
      })
    },
    changeSecondSearch(data){
      this.cursecondCategoryId = data.id;
      this.getThreeList();
    },
    getThreeList(){
      let data = {
        keyword:this.keyword,
        category_id:this.curCategoryId,
        category_second_id:this.cursecondCategoryId,
        type:1,
        is_apply:this.isApply,
        is_show_has_third:1
      };
      this.tableLoading = true;
      this.api.dataset.categoryForm(data).then((res)=>{
        this.tableLoading = false;
        //三级标签
        this.materialList = [];
        let num = this.number;
        if(this.passed){
          res.categoryThirdList=res.categoryThirdList.filter(item=>item.open_status!="1");
        }
        for(let i=0;i<res.categoryThirdList.length;i+=num){
          this.materialList.push(res.categoryThirdList.slice(i,i+num));
        }
      }).catch((err)=>{
        this.tableLoading = false;
      })
    },
    search(){
      this.getThreeList();
    },
    create(data){
      this.$emit('create',data.id)
    },
    activeMore(){
      this.$emit('activeMore')
    },
    changePage(page){
      this.page = page;
      this.getThreeList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getThreeList();
    },
    applyCategory(data){  //申请开通
      let params = {
        category_id:data.id
      };
      this.api.dataset.datasetCategoryToSiteApply(params).then((res)=>{
        this.$Message.success('申请成功');
        this.getThreeList();
      })
    },
    goMaterialCreate(data,type,generateDataRule){
      let params = {
        op:'create',
        generate_type:0,
        generate_id:data.id,
        // generate_data_rule:this.generateDataRule,//组卷模考
        type:type //0练习1考试2课后作业
      };
      if(generateDataRule >= 0){
        params.generate_data_rule = generateDataRule;
      }
      this.api.dataset.trainPublish(params).then((res)=>{
        this.$router.push({
          path:'/trainingcamp/materialCreate',
          query:{
            trainId:res.train_id,
            mapId:data.map_id,
            createType:1, //1 教材 2 认证 3 课程
          }
        })
      })
    },
    goEvaluationCreate(data,type,generateDataRule){
      let params = {
        op:'create',
        generate_type:0,
        generate_id:data.id,
        // generate_data_rule:this.generateDataRule,//组卷模考
        type:type //0练习1考试2课后作业4岗评
      };
      if(generateDataRule >= 0){
        params.generate_data_rule = generateDataRule;
      }
      this.api.dataset.trainPublishVarious(params).then((res)=>{
        this.$router.push({
          path:'/trainingcamp/random-question',
          query:{
            trainId:res.train_id,
            mapId:data.map_id,
            createType:type,
            generate_id:data.id
          }
        })

      })
    }
  }
}
</script>

<style scoped lang="scss">
.search{
  margin-top: 20px;
  font-size: 14px;
  color: #333333;
  .search-item{
    display: flex;
    justify-content: flex-start;
    font-size: 14px;

    .name{
      padding: 5px 0;
      width: 75px;
      text-align: right;
      font-weight: bold;
    }
    .cont{
      padding: 5px 0;
      margin-left: 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;

      .values{
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        transition: max-height 0.3s linear;
        overflow: hidden;

        >div{
          //margin: 0 10px 10px 5px;
          margin-right: 20px;
          margin-bottom: 10px;
          cursor: pointer;
        }
        >div.active{

          font-weight: bold;
          color: #4A79FF;
        }
      }
      .more{
        padding: 5px 0;
        width: 80px;
        text-align: right;
        cursor: pointer;
        .more-icon{
          transition: transform 0.3s linear;
        }
      }
    }
  }
}
.material-cont{
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  >div{
    flex: 1;
  }

  .material-item{
    margin-right: 20px;
    height: 126px;
    background: #F6F7FA;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .material-status{
      position: absolute;
      right: 0;
      top: 0;
      >p{
        width: 69px;
        height: 25px;
        border-radius: 4px;
        text-align: center;
        line-height: 25px;
      }

      .purple{
        background: #F0EDFF;
        color: #757BF2;
      }
      .yellow{
        background: #FAEFD0;
        color: #FE9200;
      }
    }

    .material-item-l{
      margin-right: 16px;
      width: 90px;
      height: 126px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      >img{
        width: 100%;
        height: 100%;
      }
      >p{
        width: 90px;
        height: 28px;
        background: #F0EDFF;
        border-radius: 6px 6px 0 0;
        font-size: 14px;
        color: #757BF2;
        text-align: center;
        line-height: 28px;
        position: absolute;
        left: 0;
        bottom:0;
      }
    }
    .material-item-r{
      margin-right: 16px;
      flex: 1;
      width: 0;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100px;

      .material-item-r-info-tit{
        //margin-bottom: 2px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .material-item-r-info-auth{
        //margin-bottom: 10px;
      }

      .material-item-r-info{
        font-size: 14px;
        color: #666666;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .material-item-r-info-val{
          >span{
            font-weight: bold;
          }
        }
        .material-item-r-info-btn-cont{
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .material-item-r-btn{
            width: 70px;
            height: 34px;
            background: #FFFFFF;
            border: 1px solid #E9E9E9;
            border-radius: 4px;
            color: #333333;
            text-align: center;
            line-height: 34px;
            cursor: pointer;
          }
          .active{
            background: #4A79FF;
            color: #FFFFFF;
          }
          .activeing{
            border: 1px solid #4A79FF;
            color: #4A79FF;
          }
          .actived{
            background: #EEEEEE;
            color: #999999;
          }
          .mr10{
            margin-right: 10px;
          }
        }
      }

    }
  }
}
.activate-more-btn{
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 40px;
  background: #4A79FF;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
  >img{
    margin-left: 8px;
  }
}
.page{
  margin: 20px 0;
  text-align: center;
}

</style>
