<template>
  <div class="category-manage">
    <TabCategory @changeTab="changeTab" :dataList="tabList" v-if="tabList.length"></TabCategory>
    <div class="mt20">

      <MaterialCategory v-if="curTabId == '1'"></MaterialCategory>
      <AttestationCategory :type="2" :generateDataRule="4" v-else :dataList="dataList" :categoryIds="datasetCategoryIds" @update="getData"></AttestationCategory>
    </div>

  </div>
</template>

<script>
import TabCategory from '@/components/tabCategory';
import MaterialCategory from './materialCategory';
import AttestationCategory from './attestationCategory';
export default {
  name: "categoryManage.vue",
  data(){
    return {
      curTabId:'0',
      siteId:'',
      tabList:[],
      dataList:[],
      datasetCategoryIds:[],
    }
  },
  components:{
    TabCategory,
    MaterialCategory,
    AttestationCategory
  },
  created(){
    this.siteId = this.$route.params.id;
    this.categoryToSiteDetail();
    this.getData();
  },
  methods:{
    changeTab(data){
      this.curTabId = data.id;
      this.getData();
    },
    categoryToSiteDetail(){
      let params = {
        site_id:this.siteId,
        type:this.curTabId,
      };
      this.api.site.categoryToSiteDetail(params).then((res)=>{
        this.tabList = [];
        for(let name in res.category_types){
          this.tabList.push({
            name:res.category_types[name],
            id:name,
          })
        }
      })
    },
    getData(){
      let params = {
        site_id:this.siteId,
        type:this.curTabId,
      };
      this.api.site.categoryToSiteDetail(params).then((res)=>{
        this.dataList = res.datasetCategoryTree;

        this.datasetCategoryIds = [];
        for(let name in res.datasetCategoryIds){
          this.datasetCategoryIds.push(res.datasetCategoryIds[name]);
        }
        // this.catchCategoryIds = JSON.parse(JSON.stringify(this.datasetCategoryIds));
      })
    },
  }
}
</script>

<style scoped lang="scss">
.category-manage{
  .mt20{
    margin-top: 20px;
  }
}

</style>
