<template>
  <div class="category-manage">
    <!--  <Tree :data="dataList" show-checkbox check-strictly></Tree>-->
    <!--  <div class="top">-->
    <!--    <Button type="primary" @click="handleCategory">添加/移除分类</Button>-->
    <!--  </div>-->

    <el-tree
        :data="dataList"
        node-key="child_id"
        label="name"
        show-checkbox
        :default-checked-keys="datasetCategoryIds"
        :default-expanded-keys="defaultExpandedKeys"
        @node-drop="handleDrop"
        draggable
        check-strictly
        @check="checkNode"
        :allow-drop="allowDrop"
        :allow-drag="allowDrag"
        @node-expand="nodeExpand"
        @node-collapse="nodeCollapse"
    >
    <span slot-scope="{node,data}" class="category-item">
      <span>{{data.name}}</span>
      <span >
        <Button
            type="text"
            @click.stop="() => appendAlias(data)">
            修改名称
        </Button>
      </span>
    </span>
    </el-tree>

    <Modal
        v-model="aliasModal"
        title="添加别名"
    >
      <div>
        <span style="width: 100px;text-align: right;display: inline-block">别名：</span>
        <Input v-model="alias" placeholder="请输入别名" style="width: 300px" />
      </div>
      <div slot="footer">
        <Button @click="aliasModal=false">取消</Button>
        <Button type="primary" @click="confirmAlias()">确定</Button>
      </div>
    </Modal>
    <!--  <Modal-->
    <!--      v-model="handleCategoryModal"-->
    <!--      title="添加/移除分类"-->
    <!--  >-->
    <!--    <Tree :data="dataList" show-checkbox check-strictly></Tree>-->
    <!--    <div slot="footer">-->
    <!--      <Button @click="handleCategoryModal=false">取消</Button>-->
    <!--      <Button type="primary" @click="update">更新</Button>-->
    <!--    </div>-->
    <!--  </Modal>-->
    <!--  <Button type="primary" @click="update">更新</Button>-->
  </div>
</template>

<script>
export default {
  name: "categoryManage.vue",
  data(){
    return {
      // dataList:[],
      siteId:'',
      datasetCategoryIds:[],
      idArr:[],
      alias:'',
      aliasModal:false,
      curData:{},
      catchCategoryIds:[],
      defaultExpandedKeys:[],
      // handleCategoryModal:false,
    }
  },
  props:{
    dataList:{
      type: Array,
      default:()=>{
        return []
      }
    },
    categoryIds:{
      type: Array,
      default:()=>{
        return []
      }
    }
  },
  watch:{
    categoryIds:{
      handler(newV){
        this.datasetCategoryIds = JSON.parse(JSON.stringify(newV));
        this.catchCategoryIds = JSON.parse(JSON.stringify(this.datasetCategoryIds));
        console.log("默认选中的节点",this.datasetCategoryIds)
      },
      immediate:true
    }
  },
  created(){
    this.siteId = this.$route.params.id;
    // this.categoryToSiteDetail();
  },
  methods:{
    // siteDetail(){
    //   let params = {
    //     site_id:this.siteId
    //   };
    //   this.api.site.siteDetail(params).then((res)=>{
    //
    //     this.datasetCategoryIds = [];
    //     for(let name in res.datasetCategoryIds){
    //       this.datasetCategoryIds.push(res.datasetCategoryIds[name]);
    //     }
    //     this.catchCategoryIds = JSON.parse(JSON.stringify(this.datasetCategoryIds));
    //     console.log(this.catchCategoryIds,'this.catchCategoryIdsthis.catchCategoryIds')
    //     // console.log(this.datasetCategoryIds,'this.datasetCategoryIds')
    //     // this.formatData(this.dataList);
    //   })
    // },
    // categoryToSiteDetail(){
    //   let params = {
    //     site_id:this.siteId,
    //     type:this.type,
    //   };
    //   this.api.site.categoryToSiteDetail(params).then((res)=>{
    //     this.dataList = res.datasetCategoryTree;
    //
    //     this.datasetCategoryIds = [];
    //     for(let name in res.datasetCategoryIds){
    //       this.datasetCategoryIds.push(res.datasetCategoryIds[name]);
    //     }
    //     this.catchCategoryIds = JSON.parse(JSON.stringify(this.datasetCategoryIds));
    //   })
    // },
    formatData(data){
      data.forEach((item)=>{
        this.$set(item,'title',item.name);
        let index = this.datasetCategoryIds.findIndex((sItem)=>{
          return sItem == item.child_id;
        })
        if(index > -1){
          // this.$set(item,'selected',true);
          this.$set(item,'checked',true);
        }
        if(item.children){
          this.formatData(item.children);
        }
      })
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      this.datasetCategoryIds = JSON.parse(JSON.stringify(this.catchCategoryIds));

      let params = {
        category_id:draggingNode.data.child_id,
        site_id:this.siteId,
        sort_brother_category_id:dropNode.data.child_id,
        sort_place:dropType == 'after' ? '1' : '0'
      };
      this.api.dataset.datasetCategoryToSiteVerify(params).then((res)=>{
        this.$Message.success('更新成功');
        // this.handleCategoryModal = false;
        // this.siteDetail();
      })
    },
    allowDrop(draggingNode, dropNode, type) {
      if(draggingNode.data.parent_id == dropNode.data.parent_id && type !='inner'){
        return true;
      }else{
        return false;
      }

    },
    allowDrag(){
      return true;
    },
    appendAlias(data){  //添加别名
      this.curData = data;
      this.aliasModal = true;
      this.alias = this.curData.name;
    },
    confirmAlias(){ //确定别名
      let params = {
        category_id:this.curData.child_id,
        site_id:this.siteId,
        rename:this.alias
      };
      this.api.dataset.datasetCategoryToSiteVerify(params).then((res)=>{
        this.$Message.success('修改成功');
        this.aliasModal = false;
        // this.handleCategoryModal = false;
        // this.siteDetail();
      })


    },
    checkNode(data){
      console.warn(data,"-=-=-=");
      let check = '';

      if(data.checked == '1'){
        check = 0;
      }else{
        check = 1;
      }
      let params = {
        category_id:data.child_id,
        site_id:this.siteId,
        check:check
      };
      this.api.dataset.datasetCategoryToSiteVerify(params).then((res)=>{
        this.$Message.success('更新成功');
        this.$emit('update')
        // console.log(this.defaultExpandedKeys,'defaultExpandedKeys')
        // this.handleCategoryModal = false;
        // this.siteDetail();
      })

    },
    nodeExpand(data,node){
      this.defaultExpandedKeys.push(data.child_id)
    },
    nodeCollapse(data){
      // console.log(data,'ddddd')
      if(data.children && data.children.length){
        let ids = this.getAllIds(data);
        ids.forEach((item)=>{
          let ind = this.defaultExpandedKeys.findIndex((sItem)=>{
            return sItem == item;
          })
          this.$delete(this.defaultExpandedKeys,ind);
        })
      }else{
        let ind = this.defaultExpandedKeys.findIndex((item)=>{
          return item == data.child_id;
        })
        this.$delete(this.defaultExpandedKeys,ind);
      }
    },
    getAllIds(data){
      let ids = [];
      if(data.children && data.children.length){
        ids.push(data.child_id);
        data.children.forEach((item)=>{
          let id = this.getAllIds(item);

          ids = [...ids,...id];
        });
      }

      return ids;
    },
    // handleCategory(){
    //   this.handleCategoryModal = true;
    //   this.siteForm();
    // }
  }
}
</script>

<style scoped lang="scss">
.category-manage{
  .top{
    text-align: right;
  }
  .category-item{
    font-size: 14px;
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

</style>
