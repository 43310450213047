<template>
  <div class="my-category">
    <TabCategory @changeTab="changeTab"></TabCategory>
    <div>
      <Attestation v-if="curTabId == '1'"></Attestation>
      <div v-if="curTabId == '0'" style="margin-top: 20px">
        <div class="material-category-btn" @click="addData">添加题库</div>
        <MaterialCont :isApply="0" :unButton="true"></MaterialCont>
      </div>

    </div>
    <!-- <Modal
        v-model="activeMoreModal"
        title="开通更多教材（持续更新中...）"
        @on-cancel="cancelActiveMore"
        width="1000px"
        :footer-hide="true"
    >
      <Material :number="2" :isApply="1" :passed="true"></Material>
    </Modal> -->

    <el-drawer :visible.sync="activeMoreModal" :size="800" @close="cancelActiveMore">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">开通更多教材（持续更新中...）</div>
      </div>
      <div style="padding: 20px;">
        <Material :number="2" :isApply="1" :passed="true"></Material>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Attestation from './attestationList'
import MaterialCont from './material'
import TabCategory from '@/components/tabCategory';
import Material from '@/views/trainingcamp/components/myCategory/material'
export default {
  name: "sitecategoryManage.vue",
  components:{
    Attestation,
    MaterialCont,
    TabCategory,
    Material
  },
  data(){
    return {
      curTabId:'0',
      activeMoreModal:false,

    }
  },
  methods:{
    changeTab(data){
      this.curTabId = data.id;
    },
    addData(){
      this.activeMoreModal = true;
    },
    cancelActiveMore(){
      this.activeMoreModal = false;
    }
  }
}
</script>

<style scoped lang="scss">
.my-category{
  .material-category-btn{
    width: 96px;
    height: 28px;
    background: #5782FF;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
  }
}
</style>
